@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.collections
    background: #E5E5E5

    +contrast
        background-color: $contrast-black

    &__container
        padding-top: 45px
        padding-bottom: 45px

    &__header
        +touch
            margin-bottom: 25px

        +desktop
            margin-bottom: 75px

    &__list
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 50px
        align-items: flex-start

        +hd
            grid-template-columns: repeat(4, 1fr)

        +touch
            width: 100%

    &__item
        display: flex
        flex-direction: column-reverse
        position: relative

        +mobile-small
            max-width: 400px
            height: 100%

        +mobile
            margin: 0 10px

        +touch
            max-width: 550px

        &--tile
            position: relative
            height: 337px
            transition: all 0.3s ease-in-out

            +mobile
                position: static
                height: 100%

        &--pic
            width: 100%
            height: 100%
            background-repeat: no-repeat
            background-size: cover
            background-position: center

            +mobile
                height: 333px
                position: relative

        &--icon
            opacity: 1
            pointer-events: auto
            transition: all 0.3s ease-in-out
            width: 70px
            height: 70px
            background: #FFFFFF
            display: flex
            justify-content: center
            align-items: center
            right: 0
            bottom: 0
            position: absolute
            z-index: 3
            font-size: 1.5rem

            +contrast
                background: #000

            +mobile
                left: 0

        &--active
            opacity: 0
            pointer-events: none
            transition: all 0.3s ease-in-out
            position: absolute
            width: 100%
            height: 100%
            left: 0
            top: 0
            background: rgba(#005CA9, 1)
            display: flex
            flex-direction: column
            padding: 35px
            box-sizing: border-box
            color: #FFFFFF

            +contrast
                background: #000

            +mobile
                opacity: 1
                display: block
                position: static
                background-color: #F9F9F9
                color: #161615

            

        &--content
            flex: 1
            margin-top: 20px
            margin-bottom: 20px
            overflow-y: hidden
            text-overflow: ellipsis
            p
                @extend .text--body

            +mobile
                border-bottom: 1px solid #EAEAEA
                padding-bottom: 20px
                height: 220px

            +tablet-only
                & > p
                    line-height: 1.5

        &--more
            border-top: 1px solid #FFF
            padding-top: 30px

            +mobile
                display: block
                padding-top: 20px

        &--bottom
            +mobile
                display: none
                

        &:hover, &:focus
            cursor: pointer
            text-decoration: none

            .collections__item
                &--tile
                    box-shadow: 0 0 10px 0 rgba(66, 68, 90, 0.3)
                    

                &--active
                    opacity: 1
                    pointer-events: auto
                    z-index: 2

                

    &__empty
        display: flex
        justify-content: center
        margin-top: 30px

    &__footer
        display: flex
        justify-content: center
        margin-top: 45px

    &__description
        max-width: 850px
        text-align: center
        margin: 0 auto
        padding: 0 20px

    &__text
        &--header
            @extend .text__heading--home
            text-align: center

            +touch
                margin-bottom: 25px

        &--title
            @extend .text--heading-sub
            @extend .text--color-blue
            font-weight: 500
            margin-top: 15px
            overflow: hidden
            text-overflow: ellipsis

        &--content
            @extend .text--body
            @extend .text--color-dark

        &--collection-name
            @extend .text--heading-sub
            font-weight: 500
            color: #005CA9
            margin-bottom: 20px
            display: none


            +mobile
                display: block

        &--collection-val
            @extend .text--body
            font-weight: 500
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis


        &--collection-content
            @extend .text--body

        &--collection-more
            @extend .text--title
            text-decoration: underline

            +mobile
                color: #005CA9
                text-decoration: none
                font-weight: 500


        &--link
            text-decoration: underline
